<script>
import Layout from "../../../../layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import Helper from '@/helpers/helper';

/**
 * Dashboard Component
 */
export default {
    page: {
    title: "Forêt",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Forêt",
      items: [
        {
          text: "Menu",
          href: "/",
        },
        {
          text: "Forêt",
          active: true,
        },
        {
          text: "Une Forêt",
          active: true,
        },
      ],
      forest: {},
      id: "",
      STORAGE_URL: Helper.STORAGE_URL,
    };
  },
  methods: {
    getItem() {
      fetch(Helper.route('if/forests/'+this.id), Helper.requestOptions())
      .then(response => {
          Helper.tokenChecker(response.status)
          return response.json()
      })
      .then(data => {
        console.log(data.data);
        this.forest = data.data
      })
    },
  },
  mounted() {
    this.id = this.$route.params.id
    this.getItem()
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    
    <div class="row" v-if="forest.id">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">À propos de la Forêt</h4>
            <p class="card-title-desc mb-3">
              Présentation de la Forêt <code> {{ forest.name }} </code>.
            </p>

            <div class="row mt-4">
              <div class="col-md-3">
                <dl>
                  <dt>Nom</dt>
                  <dd>{{ forest.name }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Surface</dt>
                  <dd>{{ forest.surface }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>Email</dt>
                  <dd>{{ forest.email }}</dd>
                </dl>
              </div>
              <div class="col-md-3">
                <dl>
                  <dt>CTAF</dt>
                  <dd>
                    {{ forest.ctaf.lastname +" "+forest.ctaf.firstname }}
                  </dd>
                </dl>
              </div>
            </div>


            <div class="bg-light p-2 mt-3 pt-3">
                <h6 class="font-20 text-danger">♣ Historique des responsables</h6>

                <div class="table-responsive">
                    <table class="table align-middle mb-0">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Responsable</th>
                            <th>Date Début</th>
                            <th>Date Fin</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(forest_ctaf, i) in forest.forest_ctafs" :key="forest_ctaf.id">
                            <th scope="row">{{ i + 1 }}</th>
                            <td>
                              {{ forest_ctaf.ctaf.lastname + " "+forest_ctaf.ctaf.firstname }}
                            </td>
                            <td>{{ forest_ctaf.date_start }}</td>
                            <td>{{ forest_ctaf.date_end }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            
            <div class="bg-light p-2 mt-3 pt-3">
                <h6 class="font-20 text-danger">♣ Les conventions de la Forêt</h6>

                <div class="table-responsive">
                    <table class="table align-middle mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Convention</th>
                                <th>Convention Finale</th>
                                <th>Date d'envoie</th>
                                <th>Date d'approbation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(convention, i) in forest.conventions" :key="convention.id">
                                <th scope="row">{{ i + 1 }}</th>
                                <td>
                                  <a :href="STORAGE_URL+convention.convention" v-if="convention.convention !== ''" target="_blank" class="btn btn-sm btn-success rounded-pill">
                                      <i class="bx bx-download font-size-14 align-middle mx-1 text-white"></i>
                                      Télécharger le Fichier
                                  </a>
                                </td><td>
                                  <a :href="STORAGE_URL+convention.final_convention" v-if="convention.final_convention != null" target="_blank" class="btn btn-sm btn-success rounded-pill">
                                      <i class="bx bx-download font-size-14 align-middle mx-1 text-white"></i>
                                      Télécharger le Fichier
                                  </a>
                                </td>
                                <td>{{ convention.created_at }}</td>
                                <td class="text-center">{{ convention.date_approved }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            
            <div class="bg-light p-2 mt-3 pt-3">
                <h6 class="font-20 text-danger">♣ Les PTA de la Forêt</h6>

                <div class="table-responsive">
                    <table class="table align-middle mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Titre</th>
                                <th>Fichier</th>
                                <th>Date d'envoie</th>
                                <th>Date d'approbation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(pta, i) in forest.ptas" :key="pta.id">
                                <th scope="row">{{ i + 1 }}</th>
                                <td>{{ pta.title }}</td>
                                <td>
                                  <a :href="STORAGE_URL+pta.file" v-if="pta.file !== ''" target="_blank" class="btn btn-sm btn-success rounded-pill">
                                      <i class="bx bx-download font-size-14 align-middle mx-1 text-white"></i>
                                      Télécharger le Fichier
                                  </a>
                                </td>
                                <td>{{ pta.created_at }}</td>
                                <td>{{ pta.date_approved }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <div class="bg-light p-2 mt-3 pt-3">
                <h6 class="font-20 text-danger">♣ Les Rapports de la Forêt</h6>

                <div class="table-responsive">
                    <table class="table align-middle mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Titre</th>
                                <th>Fichier</th>
                                <th>Date d'envoie</th>
                                <th>Date d'approbation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(report, i) in forest.reports" :key="report.id">
                                <th scope="row">{{ i + 1 }}</th>
                                <td>{{ report.title }}</td>
                                <td>
                                  <a :href="STORAGE_URL+report.file" v-if="report.file !== ''" target="_blank" class="btn btn-sm btn-success rounded-pill">
                                      <i class="bx bx-download font-size-14 align-middle mx-1 text-white"></i>
                                      Télécharger le Fichier
                                  </a>
                                </td>
                                <td>{{ report.created_at }}</td>
                                <td>{{ report.date_approved }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <div class="bg-light p-2 mt-3 pt-3">
                <h6 class="font-20 text-danger">♣ Les plans d'aménagement de la Forêt</h6>

                <div class="table-responsive">
                    <table class="table align-middle mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Titre</th>
                                <th>Fichier</th>
                                <th>Date d'envoie</th>
                                <th>Date d'approbation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(development_plan, i) in forest.reports" :key="development_plan.id">
                                <th scope="row">{{ i + 1 }}</th>
                                <td>{{ development_plan.title }}</td>
                                <td>
                                  <a :href="STORAGE_URL+development_plan.file" v-if="development_plan.file !== ''" target="_blank" class="btn btn-sm btn-success rounded-pill">
                                      <i class="bx bx-download font-size-14 align-middle mx-1 text-white"></i>
                                      Télécharger le Fichier
                                  </a>
                                </td>
                                <td>{{ development_plan.created_at }}</td>
                                <td>{{ development_plan.date_approved }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <div class="bg-light p-2 mt-3 pt-3">
                <h6 class="font-20 text-danger">♣ Les Cartes de la Forêt</h6>

                <div class="table-responsive">
                    <table class="table align-middle mb-0">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Titre</th>
                                <th>Fichier</th>
                                <th>Date d'envoie</th>
                                <th>Date d'approbation</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(card, i) in forest.cards" :key="card.id">
                                <th scope="row">{{ i + 1 }}</th>
                                <td>{{ card.title }}</td>
                                <td>
                                  <a :href="STORAGE_URL+card.file" v-if="card.file !== ''" target="_blank" class="btn btn-sm btn-success rounded-pill">
                                      <i class="bx bx-download font-size-14 align-middle mx-1 text-white"></i>
                                      Télécharger le Fichier
                                  </a>
                                </td>
                                <td>{{ card.created_at }}</td>
                                <td>{{ card.date_approved }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


            <div class="bg-light p-2 mt-3 pt-3">
                <h6 class="font-20 text-danger">♣ Les Photos de la Forêt</h6>

                <div class="row g-3 mt-3">
                  <div class="col-lg-4" v-for="(photo) in forest.photos" :key="photo.id">
                    <div class="border bg-white shadow h-100">
                      <a :href="STORAGE_URL+photo.file" target="_blank">
                        <img :src="STORAGE_URL+photo.file" class="w-100" style="height: 180px; object-fit: cover;" alt="">
                      </a>
                      <div class="p-3 pt-0">
                        <div class="mt-3">
                          Date d'approbation : {{ photo.date_approved }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
            </div>

          

          </div>
        </div>
      </div>
    </div>


  </Layout>
</template>
